<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div class="top-parts-cost metric-component">
    <h2
      class="card-title text-subtitle-2 color--text-primary text-uppercase font-weight-medium mb-16"
    >
      {{ $t("Top 10 Product Codes") }}
    </h2>

    <div class="charts_container">
      <div class="chart">
        <HBarChart
          v-if="chart_data[metric.node.name]"
          :chartID="metric.node.name + 'parts'"
          :chart-name="metric.node.name + 'parts'"
          :chart-data="chart_data[metric.node.name]"
          :chart-options="{
            scales: {
              xAxes: [
                {
                  ticks: {
                    minRotation: 90,
                    maxRotation: 90
                  }
                }
              ]
            }
          }"
          class="dynamicChart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { chartOptions } from "@/ui/charts/old/chartOptions";
import HBarChart from "@/ui/charts/old/HBarChart";
import { SCALE_TAB_VALUE } from "./zone_control_enum";

export default {
  components: {
    HBarChart
  },
  props: {
    metric: {
      type: Object,
      default: undefined
    },
    timeScale: {
      type: Number,
      default: undefined
    },
    machine_group_pk: {
      type: [String, Number],
      default: undefined
    }
  },
  data() {
    return {
      chartOptions: new chartOptions(),
      production: null,
      chart_data: []
    };
  },
  computed: {},
  watch: {
    timeScale: function () {
      this.chart_data = [];
      this.loadOpportunityCost();
    }
  },
  created: async function () {
    await this.loadOpportunityCost();
  },
  methods: {
    loadOpportunityCost: async function () {
      let params = {
        machine_group_id: this.machine_group_pk,
        from_date: moment().set("minute", 0).subtract(1, "month").toISOString(),
        to_date: moment().set("minute", 0).toISOString(),
        sort: "total_performance_variance"
      };
      switch (this.metric.node.automatic) {
        case "PERFORMANCE": {
          params["sort"] = "total_performance_variance";
          break;
        }
        case "QUALITY": {
          params["sort"] = "total_quality_variance";
          break;
        }
        case "OEE": {
          params["sort"] = "total_oee_variance";
          break;
        }
      }
      switch (this.timeScale) {
        case SCALE_TAB_VALUE.DAY: {
          params["from_date"] = moment().startOf("day").toISOString();
          params["to_date"] = moment().endOf("day").toISOString();
          break;
        }
        case SCALE_TAB_VALUE.WEEK: {
          params["from_date"] = moment().startOf("week").toISOString();
          params["to_date"] = moment().endOf("week").toISOString();
          break;
        }
        case SCALE_TAB_VALUE.MONTH: {
          params["from_date"] = moment().startOf("month").toISOString();
          params["to_date"] = moment().endOf("month").toISOString();
          break;
        }
        case SCALE_TAB_VALUE.YEAR: {
          params["from_date"] = moment().startOf("year").toISOString();
          params["to_date"] = moment().endOf("year").toISOString();
          break;
        }
        default: {
          // handles cases when timeScale is in the form 'start_date_as_ISO_string end_date_as_ISO_string'
          let dates = this.timeScale.split(" ");
          params["from_date"] = dates[0];
          params["to_date"] = dates[1];
          break;
        }
      }
      this.$emit("editShift", params.from_date, params.to_date);
      this.$http.get("variance/cost_per_part/", { params }).then((res) => {
        const parts = res.data;
        const data = {};
        const name = this.metric.node.name;
        data[name] = { data: [], label: [], color: [], tooltips: [] };
        parts.forEach((part) => {
          let value = 0;
          switch (this.metric.node.automatic) {
            case "QUALITY":
              value = part.total_quality_variance.value;
              break;
            case "PERFORMANCE":
              value = part.total_performance_variance.value;
              break;
            case "OEE":
              value = part.total_oee_variance.value;
              break;
          }

          data[name].data.push(value > 0 ? value.toFixed(2) : 0);
          data[name].tooltips.push(value > 0 ? "$" + value.toFixed(2) : 0);
          data[name].label.push(part.key);
          data[name].color.push(this.$colors.chart.good_gradient);
        });
        this.chart_data = this.formatDataForCharts(data);
      });
    },
    formatDataForCharts(data) {
      const keys = Object.keys(data);
      const formattedData = {};

      for (const key of keys) {
        const d = {
          datasets: [
            {
              data: data[key].data,
              type: "horizontalBar",
              backgroundColor: "rgb(247, 189, 89)",
              titles: "",
              footers: "",
              tooltips: data[key].tooltips,
              label: key
            }
          ],
          labels: data[key].label
        };

        formattedData[key] = d;
      }

      return formattedData;
    }
  }
};
</script>

<style scoped lang="scss">
.top-parts-cost {
  position: static;
  margin-left: 0;
  display: block;

  .chart {
    margin: 0;
    width: 100%;
  }

  .charts_container {
    padding: 0;

    .chart {
      height: 250px;
      width: 100%;
      position: relative;
      overflow: hidden;

      .dynamicChart {
        position: absolute;
        top: 0;
        left: -4px;
        right: 0;
        bottom: 0;
      }
    }
  }
}
</style>
