import df from "@intelycx/digital-forms";
import iot from "@intelycx/iot";
import { routes as lightHouseRoutes } from "@lighthouse/router";
import Vue from "vue";
import VueRouter from "vue-router";
import irisIngestionRoutes from "@/features/iris-ingestion/routes";
import irisGlobalSettingsRoutes from "@/features/iris-global-settings/routes";

import { hasUserRole, userRoles } from "@/utils/user";

import { AppRoutes } from "./shared/app-routes";
import store from "./store/index";
import { ticketsGroupRoutes } from "@/features/tickets-group/routes";
import { ticketsMachineRoutes } from "@/features/tickets-machine/routes";

const routes = [
  {
    name: "login",
    path: "/login",
    component: require("./views/Login.vue").default,
    displayName: false
  },
  {
    path: "/login/okta",
    component: require("./components/OktaCallback.vue").default
  },
  {
    name: "permission-denied",
    path: "/permission-denied",
    component: require("./views/PermissionDenied.vue").default,
    displayName: "Permission Denied"
  },
  {
    name: "home",
    path: "/",
    component: require("./views/Home.vue").default,
    children: [
      { name: "redirect", path: "", redirect: { name: "welcome" } },
      {
        name: "map",
        path: "/map",
        component: require("./views/Map.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "new-map",
        path: "/new_map",
        component: require("./views/NewMap.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "welcome",
        path: "/welcome",
        component: require("./views/Welcome.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "announcement",
        path: "/announcement/:announcement_id",
        component: require("./views/Announcement.vue").default,
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.machine.name,
        path: AppRoutes.machine.url(),
        component: Vue.component(
          AppRoutes.machine.name,
          () => import("./views/metrics/Machine.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.machineHour.name,
        path: AppRoutes.machineHour.url(),
        component: Vue.component(
          AppRoutes.machineHour.name,
          () => import("./features/machine-hour/MachineHour.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.machineSummary.name,
        path: AppRoutes.machineSummary.url(),
        component: Vue.component(
          AppRoutes.machineSummary.name,
          () => import("./features/machine-summary/MachineSummary.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.machineKpi.name,
        path: AppRoutes.machineKpi.url(),
        component: Vue.component(
          AppRoutes.machineSummary.name,
          () => import("./features/machine-kpi/MachineKpi.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.machineEvents.name,
        path: AppRoutes.machineEvents.url(),
        component: Vue.component(
          AppRoutes.machineEvents.name,
          () => import("./features/machine-events/MachineEvents.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.processMonitoring.name,
        path: AppRoutes.processMonitoring.url(),
        component: Vue.component(
          AppRoutes.processMonitoring.name,
          () => import("./features/process-monitoring/ProcessMonitoring.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.machineAnomalies.name,
        path: AppRoutes.machineAnomalies.url(),
        component: Vue.component(
          AppRoutes.machineAnomalies.name,
          () => import("./features/machine-anomalies/MachineAnomalies.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.groupSummary.name,
        path: AppRoutes.groupSummary.url(),
        component: Vue.component(
          AppRoutes.groupSummary.name,
          () => import("./features/group-summary/GroupSummary.vue")
        ),
        props: true,
        displayName: false
      },

      ...ticketsGroupRoutes,
      ...ticketsMachineRoutes,

      {
        name: AppRoutes.groupZoneControlNew.name,
        path: AppRoutes.groupZoneControlNew.url(),
        component: Vue.component(
          AppRoutes.groupZoneControlNew.name,
          () => import("./features/zone-control/ZoneControl.vue")
        ),
        props: true,
        displayName: false,
        redirect: { name: "zone-control-problem-solving" },
        children: [
          {
            name: "zone-control-problem-solving",
            path: "problem-solving",
            component: require("./features/zone-control/ZoneControlProblemSolving.vue").default,
            props: false,
            displayName: false
          },
          {
            name: "zone-control-weekly-summary",
            path: "weekly-summary",
            component: require("./features/zone-control-weekly/ZoneControlWeekly.vue").default,
            props: false,
            displayName: false
          },
          {
            name: "zone-control-trends",
            path: "trends",
            component: require("./features/zone-control/ZoneControlTrends.vue").default,
            props: false,
            displayName: false
          }
        ]
      },
      {
        name: AppRoutes.groupStatus.name,
        path: AppRoutes.groupStatus.url(),
        component: Vue.component(
          AppRoutes.groupStatus.name,
          () => import("./features/group-status/GroupStatus.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.qualityEventsGroup.name,
        path: AppRoutes.qualityEventsGroup.url(),
        component: Vue.component(
          AppRoutes.qualityEventsGroup.name,
          () => import("@/features/quality-events-group/QualityEventsGroup.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.qualityEventsMachine.name,
        path: AppRoutes.qualityEventsMachine.url(),
        component: Vue.component(
          AppRoutes.qualityEventsMachine.name,
          () => import("@/features/quality-events-machine/QualityEventsMachine.vue")
        ),
        props: true,
        displayName: false
      },

      {
        name: AppRoutes.operatorDashboard.name,
        path: AppRoutes.operatorDashboard.url(),
        component: Vue.component(
          AppRoutes.operatorDashboard.name,
          () => import("./features/operator-dashboard/OperatorDashboard.vue")
        ),
        props: true,
        displayName: false
      },
      {
        name: AppRoutes.irisChat.name,
        path: AppRoutes.irisChat.url,
        component: Vue.component(
          AppRoutes.irisChat.name,
          () => import("./views/ai-assistant/AIAssistant.vue")
        ),
        props: false,
        displayName: false
      },
      {
        name: "group",
        path: "/group",
        component: require("./views/metrics/group/Home.vue").default,
        props: true,
        displayName: "Machine Group",
        children: [
          {
            name: "group-realtime",
            path: "/group/:level/:machine_group_id/:machine_group_pk/realtime",
            component: require("./views/metrics/group/RealtimeStatus.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "group-summary",
            path: "/group/:level/:machine_group_id/:machine_group_pk/summary",
            component: require("./views/metrics/group/MachineSummary.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "zone-control",
            path: "/group/:level/:machine_group_id/:machine_group_pk/zone_control/:safety?",
            component: require("./views/ldms/zone-control/ZoneControl.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "tickets",
            path: "/group/:level/:machine_group_id/:machine_group_pk/tickets/:input_tab?",
            component: require("./views/metrics/group/Tickets.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "quality",
            path: "/group/:level/:machine_group_id/:machine_group_pk/quality",
            component: require("./views/metrics/group/Quality.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "subgroup-breakdown",
            path: "/group/:level/:machine_group_id/:machine_group_pk/subgroup-breakdown",
            component: require("./views/metrics/group/SubgroupBreakdown.vue").default,
            props: true,
            displayName: false
          }
        ]
      },
      {
        name: "ticket",
        path: "/issue_ticket/:ticket_id",
        component: require("./components/ticket/Issue.vue").default,
        props: true,
        displayName: false
      },
      {
        name: "help_ticket",
        path: "/help_ticket/:ticket_id",
        component: require("./components/ticket/Help.vue").default,
        props: true,
        displayName: false
      },
      {
        name: "settings",
        path: "/settings",
        component: require("./views/UserSettings.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "config",
        path: "/config",
        component: require("./views/Configurations.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "audits",
        path: "/audits",
        component: require("./views/audits/Parent.vue").default,
        displayName: "Audits",
        children: [
          {
            name: "audit-types",
            path: "/audits/types",
            component: require("./views/audits/TypesTable.vue").default,
            props: false,
            displayName: false
          },
          {
            name: "new-audit-type",
            path: "/audits/newType",
            component: require("./views/audits/CreateAuditType.vue").default,
            props: false,
            displayName: false
          },
          {
            name: "new-audit-revision",
            path: "/audits/revision/:auditTypeId",
            component: require("./views/audits/CreateAuditVersion.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "audit-form",
            path: "/audits/form/:auditTypeId",
            component: require("./views/audits/ResponseForm.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "completed-audits",
            path: "/audits/completedAudits",
            component: require("./views/audits/CompletedAudits.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "audit-findings",
            path: "/audits/findings",
            component: require("./views/audits/FindingsTable.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "audit-response",
            path: "/audits/completedAudits/:auditTypeId/:responseFormId",
            component: require("./views/audits/Response.vue").default,
            props: true,
            displayName: false
          }
        ]
      },
      {
        name: "analytics",
        path: "/analytics",
        component: require("./views/Analytics.vue").default,
        displayName: "Analytics",
        children: []
      },
      {
        name: "operator",
        path: "/analytics/operator",
        component: require("./views/analytics/operator.vue").default,
        props: false,
        displayName: false,
        children: [
          {
            name: "realtime",
            path: "realtime",
            component: require("./views/analytics/operator/realtime.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "leaderboard",
            path: "leaderboard",
            component: require("./views/analytics/operator/leaderboard.vue").default,
            props: false,
            displayName: false
          },
          {
            name: "analytics",
            path: "analytics",
            component: require("./views/analytics/operator/analytics.vue").default,
            props: false,
            displayName: false
          }
        ]
      },
      {
        name: "tla_report",
        path: "/analytics/tla_report",
        component: require("./views/analytics/tla_report.vue").default,
        props: false,
        displayName: false,
        children: []
      },
      {
        name: "production_report",
        path: "/analytics/production_report",
        component: require("./views/analytics/production_report.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "level2",
        path: "/analytics/level2",
        component: require("./views/analytics/level2/Level2Analytics.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "lmds-analytics",
        path: "/analytics/ldms-analytics",
        component: require("./views/ldms/ldms-analytics/LDMSAnalytics.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "p911-analytics",
        path: "/analytics/p911/p911-analytics",
        component: require("./views/analytics/p911/P911Analytics.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "scrap-analytics",
        path: "/analytics/scrap/scrap-analytics",
        component: require("./views/analytics/scrap/ScrapAnalytics.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "ai-vision",
        path: "/analytics/aivision/aivision-analytics",
        component: require("./views/analytics/aivision/AiVisionAnalytics.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "aeyevision/asset",
        path: "/aeyevision/asset/:assetId",
        component: require("./views/aivision/AiVision.vue").default,
        props: true,
        displayName: "AEye Vision"
      },
      {
        name: "aeyevision/group",
        path: "/aeyevision/group/:groupId",
        component: require("./views/aivision/AiVision.vue").default,
        props: true,
        displayName: false
      },
      {
        name: "application-monitoring",
        path: "/application-monitoring",
        component: require("./views/application-monitoring/ApplicationMonitoring.vue").default,
        displayName: "Application Monitoring",
        redirect: {
          name: "application-issue-tickets"
        },
        children: [
          {
            name: "application-issue-tickets",
            path: "/application-monitoring/tickets",
            component: require("./views/application-monitoring/Tickets.vue").default,
            props: false,
            displayName: false
          },
          {
            name: "application-issue-ticket",
            path: "/application-monitoring/ticket-details/:ticketId",
            component: require("./views/application-monitoring/TicketDetails.vue").default,
            props: true,
            displayName: false
          },
          {
            name: "error-logs",
            path: "/application-monitoring/error-logs",
            component: require("./views/application-monitoring/ErrorLogs.vue").default,
            props: false,
            displayName: false
          }
        ]
      },
      {
        name: "digital-twin",
        path: "/digital-twin",
        component: require("./views/digital-twin/DigitalTwinBundle.vue").default,
        props: false,
        displayName: false
      },
      {
        name: "Raw data",
        path: "/raw-data",
        component: require("./views/RawData.vue").default,
        displayName: "Raw Data"
      },
      ...lightHouseRoutes,
      ...df.routes,
      ...iot.routes,
      ...irisIngestionRoutes,
      ...irisGlobalSettingsRoutes
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

router.beforeEach((to, from, next) => {
  if (
    to.path.includes("application-monitoring") &&
    !hasUserRole(store.state.session.user, userRoles.ApplicationAdmin)
  ) {
    next({
      path: from.path ? from.path : "/"
    });
  }
  if (
    to.path.includes("lighthouse") &&
    !hasUserRole(store.state.session.user, userRoles.Lighthouse)
  ) {
    next({
      path: from.path ? from.path : "/"
    });
  }
  next();
});

export default router;
