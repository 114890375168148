<template>
  <v-dialog
    :value="value"
    scrollable
    :max-width="maxWidth"
    v-bind="$attrs"
    @input="(val) => $emit('input', val)"
    v-on="$listeners"
  >
    <v-card class="dialog-wrapper">
      <DialogClose
        class="dialog-close"
        @click.native="$emit('input', false)"
      />
      <v-card-title class="px-6 py-5 text-h6 dialog-title">
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text class="pt-5 body-1">
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions class="flex justify-end mb-2">
        <slot name="actions">
          <Btn
            class="action-button ml-3"
            @click.native="$emit('input', false)"
          >
            {{ $t("Cancel") }}
          </Btn>
          <Btn
            variant="primary"
            :disabled="submitDisabled"
            :loading="submitLoading"
            class="action-button ml-3"
            @click="submitDialog"
          >
            <slot name="submitActionName">{{ $t("Submit") }}</slot>
          </Btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogClose from "@/ui/DialogClose/DialogClose.vue";
import Btn from "@/ui/Btn/Btn.vue";

export default {
  name: "ModalDialog",
  components: { DialogClose, Btn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    submitLoading: {
      type: Boolean,
      default: false
    },
    maxWidth: { type: String, default: "768px" }
  },
  emits: ["submit-action"],
  setup(_, context) {
    const submitDialog = () => {
      context.emit("submit-action");
      context.emit("input", false);
    };

    return {
      submitDialog
    };
  }
};
</script>

<style scoped>
.dialog-wrapper {
  background-color: var(--bg-secondary);
}

.dialog-close {
  top: 16px;
  right: 16px;
}

.dialog-title {
  border-bottom: 1px solid var(--border-secondary);
}

.action-button {
  min-width: 149px !important;
}
</style>
