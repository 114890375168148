<template>
  <transition-group
    name="fade"
    tag="div"
  >
    <div
      v-show="Object.keys(enlargedTable).length === 0"
      key="zoneControlProblemSolving"
    >
      <ZoneControlTickets
        v-for="table in issueTables"
        :key="table.id"
        :from_date="table.dates.start && table.dates.start.toISOString()"
        :to_date="table.dates.end && table.dates.end.toISOString()"
        :title="table.title"
        :portal-node-id="table.portalNodeId"
        @click="setEnlargedTable(table)"
      />
    </div>

    <ZoneControlEnlargedTable
      v-if="Object.keys(enlargedTable).length > 0"
      ref="enlargedTableRef"
      key="enlargedZoneControlProblemSolving"
      :issues-title="issuesTitle"
      :enlarged-table="enlargedTable"
      :reasons="reasons"
      :machine-group-pk="machineGroupPk"
      :machines="machines"
      :selected-interval="selectedInterval"
      :set-preset-dates="setPresetDates"
      :get-inprocess="getInprocess"
      :get-latest="getLatest"
      :get-oldest="getOldest"
      :enlarged-issue-headers="enlargedIssueHeaders"
      :load-issue-page="loadIssuePage"
      :handle-items-per-page-change="handleItemsPerPageChange"
      :get-status-icon="getStatusIcon"
      :close-table="closeTable"
      :from-date="fromDate"
      :to-date="toDate"
      :handle-preset-click="handlePresetClick"
      :intervals="intervals"
      :load-issues="loadIssues"
    />
  </transition-group>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import ZoneControlEnlargedTable from "./components/ZoneControlEnlargedTable.vue";
import ZoneControlTickets from "@/features/zone-control/ZoneControlTickets.vue";
import { useSettingsQuery } from "@/common/settings/useSettingsQuery";
import { computed } from "@vue/composition-api";
import { useIntl } from "@/shared/useIntl";

export default {
  components: {
    ZoneControlTickets,
    ZoneControlEnlargedTable
  },
  setup() {
    const { settings } = useSettingsQuery();
    const { $t } = useIntl();

    const issueTables = computed(() => {
      if (!settings.value) {
        return [];
      }

      const { zcbEscalationTime } = settings.value;
      return [
        {
          title: $t("Unresolved Issues for the Week"),
          dates: {
            start: moment().startOf("day").subtract(7, "days"),
            end: moment().subtract(zcbEscalationTime, "hours")
          },
          issues: [],
          loading: false,
          itemsPerPage: 10,
          total: 0,
          current: 1,
          portalNodeId: "filters-1",
          load: false,
          next: null,
          prev: null
        },
        {
          title: $t("Ongoing Unresolved Issues for the Month"),
          dates: {
            start: moment().startOf("day").subtract(30, "days"),
            end: moment().startOf("day").subtract(7, "days")
          },
          issues: [],
          loading: false,
          itemsPerPage: 10,
          total: 0,
          current: 1,
          portalNodeId: "filters-2",
          load: false,
          next: null,
          prev: null
        },
        {
          title: $t("Complex Unresolved Issues"),
          dates: {
            start: moment().startOf("day").subtract(1200, "days"),
            end: moment().startOf("day").subtract(30, "days")
          },
          issues: [],
          loading: false,
          itemsPerPage: 10,
          total: 0,
          current: 1,
          portalNodeId: "filters-3",
          load: false,
          next: null,
          prev: null
        }
      ];
    });

    return {
      moment,
      issueTables
    };
  },
  data() {
    return {
      issueHeaders: [
        { text: this.$t("Machine or Group"), value: "relation", sortable: false, width: "25%" },
        {
          text: this.$t("Issue"),
          value: "reason",
          align: "center",
          sortable: false,
          cellClass: "reasonText",
          width: "calc(75% - 30px)"
        },
        {
          text: this.$t("Status"),
          value: "status",
          align: "center",
          sortable: false,
          width: "30px"
        }
      ],
      enlargedIssueHeaders: [
        { text: this.$t("Title"), value: "node.title" },
        {
          text: this.$t("Machine or Group"),
          value: "relation",
          sortable: false,
          width: "calc(25% - 18px)"
        },
        {
          text: this.$t("Issue"),
          value: "node.reason",
          align: "center",
          sortable: false,
          width: "25%"
        },
        {
          text: this.$t("Short Term Countermeasures"),
          value: "countermeasures",
          align: "center",
          sortable: false,
          width: "50px"
        },
        {
          text: this.$t("Root Cause"),
          value: "cause",
          align: "center",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t("Long Term Solutions"),
          value: "solution",
          align: "center",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t("Status"),
          value: "status",
          align: "center",
          sortable: false,
          width: "150px"
        },
        { text: "", value: "launch", align: "center", sortable: false, width: "40px" }
      ],
      enlargedTable: {},
      reasons: [],
      reasonIds: [],
      machineIds: [],
      machineGroupIds: [],
      intervals: [
        { name: this.$t("Default"), value: "card" },
        { name: this.$t("Shift"), value: "shift" },
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ],
      selectedInterval: "card",
      fromDate: "",
      toDate: "",
      level: this.$route.params.level,
      machine_group_id: this.$route.params.machine_group_id,
      machine_group_pk: this.$route.params.machine_group_pk,
      issuesTitle: ""
    };
  },
  computed: {
    ...mapGetters({
      machines: "dbCache/machines"
    }),
    levelId() {
      return parseInt(this.level, 10);
    },
    machineGroupPk() {
      return parseInt(this.machine_group_pk, 10);
    }
  },
  async created() {
    await this.loadIssueTypes();
  },
  methods: {
    setEnlargedTable(issueTable) {
      this.enlargedTable = issueTable;
      this.issuesTitle = issueTable.title;
    },
    handlePresetClick(value) {
      this.selectedInterval = value;
      this.$refs.enlargedTableRef.$refs.summary.setPreset(value);
    },
    setPresetDates(fromDate, toDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    closeTable() {
      this.selectedInterval = "card";
      this.enlargedTable = {};
    },
    handleItemsPerPageChange(value, table) {
      this.loadIssues(table);
    },
    loadIssueTypes() {
      this.reasons = [];
      this.loadingTickets = true;
      const query = `query ($machineGroupId: ID!){
        issueReasons{
          edges{
            node{
              id
              pk
              text
              issueType{
                id
                pk
              }
            }
          }
        }
        machineGroup(id: $machineGroupId){
          id
          ... on MachineGroupType{
            id
            pk
            allMachines{
              id
              pk
            }
            allSubGroups{
              id
              pk
            }
          }
        }
      }`;
      const variables = { machineGroupId: this.machine_group_id };
      return this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.reasons = res.data.data.issueReasons.edges;
          let reasonIds = [6, 7, 8];
          this.reasons.forEach((reason) => {
            if (reason.node.pk && !(reason.node.pk in reasonIds)) {
              reasonIds.push(reason.node.pk);
            }
          });
          this.machineIds = res.data.data.machineGroup.allMachines.map((machine) => machine.pk);
          this.machineGroupIds = res.data.data.machineGroup.allSubGroups.map((group) => group.pk);
          this.machineGroupIds.push(this.machineGroupPk);
          this.reasonIds = reasonIds;
          this.issueTables.forEach((table) => {
            this.loadIssues(table);
          });
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadIssuePage(table, pagination) {
      if (pagination.page > table.page) {
        table.load = {
          after: table.next,
          before: false,
          first: table.itemsPerPage
        };
        this.loadIssues(table);
      } else if (pagination.page < table.page) {
        table.load = {
          after: false,
          before: table.prev,
          last: table.itemsPerPage
        };
        this.loadIssues(table);
      }
      table.page = pagination.page;
    },
    loadIssues(table) {
      table.loading = true;
      const query = `query (
        $andOr: GrapheneElasticAndORIssueSearchConnectionAndOrFilter,
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        issues (
          simpleQueryString: $search,
          filter: $filters,
          ordering: $ordering,
          andOr: $andOr,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [automatic]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              id,
              title,
              reason,
              countermeasures,
              cause,
              solution
              openedDate,
              ticketedDate,
              assignedToNames,
              issueStartDate,
              issueEndDate,
              machineId,
              machineName,
              machineGroupName,
              issueTypeId,
              issueTypeName,
            }
          }
        }
      }`;
      let ordering = { ticketedDate: "DESC" };
      const variables = {
        filters: {
          ticketed: { value: true },
          closedDate: { exists: false },
          reasonId: { in: this.reasonIds }
        },
        andOr: {
          MachineOrMachineGroup: {
            machineIds: this.machineIds,
            machineGroupIds: this.machineGroupIds
          }
        },
        ordering: ordering,
        before: false,
        after: false,
        search: ""
      };
      if (table.dates.start && table.dates.end) {
        variables["filters"]["ticketedDate"] = {
          range: {
            lower: { datetime: table.dates.start.toISOString() },
            upper: { datetime: table.dates.end.toISOString() }
          }
        };
      } else if (table.dates.end) {
        variables["filters"]["ticketedDate"] = {
          lt: { datetime: table.dates.end.toISOString() }
        };
      }
      if (table.load) {
        Object.assign(variables, table.load);
      } else {
        variables["first"] = table.itemsPerPage;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          table.issues = res.data.data.issues.edges;
          table.total = res.data.data.issues.facets.automatic.doc_count;
          table.next = res.data.data.issues.pageInfo.endCursor;
          table.prev = res.data.data.issues.pageInfo.startCursor;
          table.load = false;
          table.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    getStatusIcon(issue) {
      let icon = "mdi-circle-outline";
      if (issue.node.cause) {
        icon = "mdi-circle-slice-4";
      }
      if (issue.node.solution) {
        icon = "mdi-circle-slice-6";
      }
      if (issue.node.closedDate) {
        icon = "mdi-circle-slice-8";
      }
      return icon;
    },
    getInprocess(issues) {
      let total = 0;
      issues.forEach((issue) => {
        if (issue.node.cause) {
          total++;
        }
      });
      return total;
    },
    getLatest(issues) {
      let latest = false;
      issues.forEach((issue) => {
        let issueDate = moment(issue.node.ticketedDate);
        if (!latest || issueDate.isAfter(latest)) {
          latest = issueDate;
        }
      });
      if (latest) {
        return moment().diff(latest, "days");
      }
      return "";
    },
    getOldest(issues) {
      let oldest = false;
      issues.forEach((issue) => {
        let issueDate = moment(issue.node.ticketedDate);
        if (!oldest || issueDate.isBefore(oldest)) {
          oldest = issueDate;
        }
      });
      if (oldest) {
        return moment().diff(oldest, "days");
      }
      return "";
    },
    addIssue(issues, issue) {
      issues.unshift({ node: issue });
      this.issueTables = [...this.issueTables];
    }
  }
};
</script>
