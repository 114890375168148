<template>
  <div class="enlarged-trend">
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="title">
        <h3 class="text-subtitle-1 text-uppercase mb-2">{{ enlargedTrend.name }}</h3>
        <div class="text-h6 font-weight-medium">{{ currentDateInterval }}</div>
      </div>
      <div class="d-flex align-center">
        <div>
          <BtnToggle
            v-if="!isSafetyTrend"
            :value="scaleTab"
            class="inline-block"
          >
            <v-btn
              :value="3"
              @click="scaleTab = 3"
            >
              {{ $t("day") }}
            </v-btn>

            <v-btn
              :value="0"
              @click="scaleTab = 0"
            >
              {{ $t("week") }}
            </v-btn>

            <v-btn
              :value="1"
              @click="scaleTab = 1"
            >
              {{ $t("month") }}
            </v-btn>

            <v-btn
              :value="2"
              @click="scaleTab = 2"
            >
              {{ $t("year") }}
            </v-btn>
          </BtnToggle>

          <v-btn
            v-if="!isSafetyTrend"
            large
            icon
            @click="showDateRangePicker = true"
          >
            <v-icon color="primary">mdi-calendar-range-outline</v-icon>
          </v-btn>
        </div>

        <ZoneControlSafetyEvent
          v-if="isSafetyTrend"
          :selected-event="selectedSafetyEvent"
          @safetyEventSubmitted="incrementSafetyEventSubmits"
          @clear-selected-event="selectedSafetyEvent = null"
        />

        <Btn
          class="expand color--primary ml-6"
          icon
          large
          @click.stop.prevent="setEnlargedTrend({})"
        >
          <v-icon color="primary">mdi-arrow-collapse</v-icon>
        </Btn>
      </div>

      <v-dialog
        v-model="showDateRangePicker"
        max-width="636"
      >
        <v-card>
          <DialogCloseButton
            class="close-wrapper"
            :on-click="() => (showDateRangePicker = false)"
          />
          <v-card-title class="pb-5">{{ $t("Custom Date Range") }}</v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <BtnToggle
                  v-model="customDateInterval"
                  class="mb-5"
                >
                  <v-btn
                    :value="0"
                    @click="customDateInterval = 0"
                  >
                    {{ $t("day") }}
                  </v-btn>

                  <v-btn
                    :value="1"
                    @click="customDateInterval = 1"
                  >
                    {{ $t("week") }}
                  </v-btn>

                  <v-btn
                    :value="2"
                    @click="customDateInterval = 2"
                  >
                    {{ $t("month") }}
                  </v-btn>

                  <v-btn
                    :value="3"
                    @click="customDateInterval = 3"
                  >
                    {{ $t("year") }}
                  </v-btn>
                </BtnToggle>
              </v-row>
              <v-row class="d-flex justify-start align-center">
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                  :locale="$i18n.locale()"
                />
                <v-date-picker
                  v-model="endDate"
                  :min="startDate"
                  no-title
                  scrollable
                  :locale="$i18n.locale()"
                />
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions class="pb-6 px-6 pt-0">
            <v-spacer />
            <Btn
              large
              variant="error"
              width="110"
              @click="showDateRangePicker = false"
              >{{ $t("Cancel") }}</Btn
            >
            <Btn
              large
              variant="primary"
              :disabled="!dateRangeValid"
              width="110"
              class="ml-4"
              @click="applyDateRange"
              >{{ $t("Apply") }}</Btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div
      class="card-content"
      :style="isSafetyTrend ? 'overflow: hidden' : ''"
    >
      <ZoneControlSafetyCard
        v-if="isSafetyTrend"
        :level="levelId"
        :machine_group_pk="machineGroupPk"
        :safety-event-submits="safetyEventSubmits"
        @editSafetyEvent="handleEditSafetyEvent"
        @editShift="handleEditShift"
      />
      <ZoneControlTrendCard
        v-else
        :trend="{ ...enlargedTrend, machine_group_id }"
        :time-scale="scaleTab"
        @editShift="handleEditShift"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import Btn from "@/ui/Btn";
import BtnToggle from "@/components/BtnToggle.vue";
import DialogCloseButton from "@/components/DialogCloseButton";
import ZoneControlSafetyCard from "./ZoneControlSafetyCard.vue";
import ZoneControlTrendCard from "./ZoneControlTrendCard.vue";

import ZoneControlSafetyEvent from "./ZoneControlSafetyEvent.vue";

export default {
  name: "ZoneControlEnlargedTrend",
  components: {
    Btn,
    BtnToggle,
    DialogCloseButton,
    ZoneControlSafetyEvent,
    ZoneControlSafetyCard,
    ZoneControlTrendCard
  },
  props: {
    enlargedTrend: { type: Object, default: () => {} },
    setEnlargedTrend: { type: Function, default: () => null },
    levelId: { type: Number, default: undefined },
    machine_group_id: { type: String, default: "" },
    machineGroupPk: { type: Number, default: undefined }
  },
  data() {
    return {
      scaleTab: 0,
      showDateRangePicker: false,
      customDateInterval: 0,
      startDate: moment().startOf("week").toISOString(),
      endDate: moment().endOf("week").toISOString(),
      dateRangeValid: false,
      selectedSafetyEvent: null,
      safetyEventSubmits: 0
    };
  },
  computed: {
    isSafetyTrend() {
      return this.enlargedTrend.name === "Safety";
    },
    currentDateInterval() {
      return `${moment(this.startDate).format("DD MMM")} - ${moment(this.endDate).format("DD MMM, YYYY")}`;
    }
  },
  watch: {
    startDate() {
      this.determineDateRangeValid();
    },
    endDate() {
      this.determineDateRangeValid();
    }
  },
  methods: {
    applyDateRange() {
      const scales = ["day", "week", "month", "year"];
      this.scaleTab = `${moment(this.startDate).startOf("day").toISOString()} ${moment(this.endDate)
        .endOf("day")
        .toISOString()} ${scales[this.customDateInterval]}`;
      this.showDateRangePicker = false;
    },
    determineDateRangeValid() {
      // ensure that startDate and endDate are both set, and that startDate is before endDate
      if (this.startDate && this.endDate) {
        this.dateRangeValid = moment(this.startDate).isBefore(moment(this.endDate));
      } else {
        this.dateRangeValid = false;
      }
    },
    handleEditSafetyEvent(selectedEvent) {
      this.selectedSafetyEvent = selectedEvent;
    },
    incrementSafetyEventSubmits() {
      this.safetyEventSubmits++;
    },
    handleEditShift(from, to) {
      this.startDate = from;
      this.endDate = to;
    }
  }
};
</script>

<style lang="scss" module>
.close-wrapper .spacer.close-spacer {
  display: none;
}

.close-wrapper .close-button {
  right: 10px;
  top: 10px;
}
</style>
