<template>
  <div class="root">
    <v-tooltip
      bottom
      max-width="300"
    >
      <template #activator="{ on, attrs }">
        <div v-on="on">
          <v-btn
            :class="{ selected: isSelected }"
            :disabled="disabled"
            class="root-btn"
            primary
            text
            outlined
            v-bind="attrs"
            v-on="on"
            @click="action"
          >
            {{ text }}
          </v-btn>
        </div>
      </template>
      <span class="root-text">{{ text }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: "ZoneControlReason",
  props: {
    action: {
      type: Function,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  width: 100%;
}

.root-btn {
  height: 36px;
  width: 100%;
}

.selected:before {
  opacity: 0.08 !important;
}

.root-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: normal;
  display: block;
  line-height: 1.2em;
  white-space: nowrap;
}
</style>
