<template>
  <DynamicTable
    class="root"
    :items="items"
    :headers="headers"
    :loading-text="$t('Loading Help Tickets... Please wait')"
    :dynamic-slots="['id', 'total', ...dynamicSlots]"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template #[`item.id`]="{ item }">
      <div v-if="item && item.id === ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID">
        <Btn
          icon
          ghost
          @click="expanded = !expanded"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </Btn>
      </div>
      <div v-else></div>
    </template>

    <template #[`item.total`]="{ item }">
      <div>
        <ZoneControlWeeklyTableCell
          v-if="item"
          :actual="item.total_actual"
          :target="item.total_target"
          :shift-name="
            item.id === ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID
              ? $t('TOTAL')
              : (shifts[item.id] && shifts[item.id].name) || $t('UNKNOWN')
          "
          :is-total="item.id === ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID"
        />
      </div>
    </template>

    <template
      v-for="slotName in dynamicSlots"
      #[`item.${slotName}`]="{ item }"
    >
      <ZoneControlWeeklyTableCell
        v-if="item && item.days[slotName]"
        :key="slotName"
        :actual="item.days[slotName].actual"
        :target="item.days[slotName].target"
      >
      </ZoneControlWeeklyTableCell>
    </template>
  </DynamicTable>
</template>

<script>
import { computed, ref, toRefs } from "@vue/composition-api";
import moment from "moment-timezone";
import { useIntl } from "@/shared/useIntl";
import DynamicTable from "@/ui/DynamicTable/DynamicTable.vue";
import Btn from "@/ui/Btn/Btn.vue";
import ZoneControlWeeklyTableCell from "./ZoneControlWeeklyTableCell.vue";
import { unit, UnitTypes } from "../../../utils/unit";
import sortBy from "lodash/sortBy";

const ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID = -1;

export default {
  components: {
    DynamicTable,
    ZoneControlWeeklyTableCell,
    Btn
  },
  props: {
    shifts: {
      type: Object,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    }
  },
  setup(props) {
    const { $t } = useIntl();
    const { data } = toRefs(props);
    const expanded = ref(false);
    const headers = computed(() => {
      const result = [
        { text: "", value: "id", sortable: false, width: "36px" },
        { text: $t("Shift (Produced / Target)"), value: "total", sortable: false, width: "280px" },
        ...moment.weekdays().map((day, index) => {
          return {
            text: day,
            value: index,
            sortable: false
          };
        })
      ];

      return result;
    });

    const dynamicSlots = computed(() => {
      return headers.value
        .filter((val, index) => index !== 0 && index !== 1)
        .map((item) => item.value);
    });

    const items = computed(() => {
      if (!data.value) {
        return null;
      }

      const result = data.value && sortBy(data.value.shifts, "id");

      if (result) {
        result.unshift({
          ...data.value.total,
          id: ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID,
          total_actual: data.value.total.actual,
          total_target: data.value.total.target
        });
      }

      if (expanded.value) {
        return result;
      } else {
        return [result[0]];
      }
    });

    return {
      headers,
      dynamicSlots,
      items,
      moment,
      expanded,
      UnitTypes,
      unit,
      ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID
    };
  }
};
</script>

<style scoped>
::v-deep .root table {
  table-layout: fixed !important;
  min-width: 1200px;

  td:first-child {
    padding: 0 4px;
    min-width: auto;
  }
}
</style>
