<template>
  <div>
    <v-dialog
      v-model="showDialog"
      class="root"
      max-width="576px"
      persistent
    >
      <template #activator="{ on, attrs }">
        <v-hover>
          <div class="dialogBtn">
            <Btn
              large
              class="mr-3 button-issue"
              variant="primary"
              primary
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("Add Issue") }}
            </Btn>
          </div>
        </v-hover>
      </template>
      <v-card class="dialog-wrapper">
        <DialogClose
          class="dialog-close"
          :on-click="() => (showDialog = false)"
        />
        <v-card-title class="px-6 py-5 text-h6 dialog-title">
          {{ $t("Submit LDMS Issue") }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-autocomplete
            :value="machine"
            :items="machines"
            :disabled="Object.keys(machineGroup).length > 0"
            :menu-props="{ maxHeight: '400', closeOnClick: true, closeOnContentClick: true }"
            :label="$t('Machines')"
            clearable
            item-text="name"
            return-object
            hide-details
            filled
            @input="machine = $event || {}"
          >
          </v-autocomplete>

          <div class="mt-5">
            <v-autocomplete
              v-model="issueType"
              :items="issueTypes"
              :label="$t('Issue Type')"
              clearable
              item-text="name"
              return-object
              hide-details
              filled
            >
              <template #item="{ item }">
                <!-- eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys -->
                {{ $t(item.name) }}
              </template>
              <template #selection="{ item }">
                <!-- eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys -->
                {{ $t(item.name) }}
              </template>
            </v-autocomplete>
          </div>

          <div class="button-column mt-6 d-flex flex-column">
            <ZoneControlReason
              v-for="reason in reasonsForSelected"
              :key="reason.node.id"
              class="reason long-reason"
              :text="reason.node.text"
              :is-selected="selected === reason"
              :action="
                () => {
                  selected = reason;
                }
              "
            />

            <ZoneControlReason
              :text="$t('Other')"
              :is-selected="selected === 'other'"
              :action="
                () => {
                  selected = 'other';
                }
              "
            />
          </div>
          <div
            v-if="selected === 'other'"
            class="dialog-textarea mt-6"
          >
            <v-text-field
              v-model="otherText"
              filled
              :label="$t('Issue')"
            >
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <Btn
            class="dialog-btn"
            @click="showDialog = false"
          >
            {{ $t("Cancel") }}
          </Btn>
          <Btn
            class="dialog-btn"
            variant="primary"
            :disabled="disableSubmit"
            :loading="saving"
            @click="createIssue"
          >
            {{ $t("Submit") }}
          </Btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import Btn from "@/ui/Btn";
import DialogClose from "@/ui/DialogClose/DialogClose.vue";
import ZoneControlReason from "@/features/zone-control/components/ZoneControlReason.vue";

export default {
  components: { ZoneControlReason, DialogClose, Btn },
  props: {
    machine_group_id: { type: String, default: "" },
    machine_group_pk: { type: Number, default: undefined }
  },

  data() {
    return {
      showDialog: false,
      selected: false,
      saving: false,
      otherText: "",
      reasons: [],
      machines: [],
      machine: {},
      machineGroups: [],
      machineGroup: {},
      time: "",
      applyToAllMachines: false,
      menu: false,
      issueDate: moment().toDate().toISOString().substr(0, 10),
      issueTime: moment().startOf("hour").format("H:m"),
      activeTab: "calendar",
      issueType: null,
      issueTypes: [
        { name: "Performance", value: "performance" },
        { name: "Quality", value: "quality" },
        { name: "Availability", value: "availability" }
      ]
    };
  },
  computed: {
    disableSubmit() {
      if (!this.selected) {
        return true;
      }

      return this.selected === "other" && (this.otherText === "" || this.otherText == null);
    },
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    reasonsForSelected() {
      let reasons = [];
      if (this.machine && this.machine.reasonSet) {
        reasons = this.machine.reasonSet.edges;
      } else if (this.machineGroup && this.machineGroup.reasonSet) {
        reasons = this.machineGroup.reasonSet.edges;
      } else {
        reasons = this.reasons;
      }

      reasons = reasons.filter((reason) => {
        if (this.issueType === null) {
          return true;
        } else if (this.issueType.value === "performance") {
          return reason.node.performance;
        } else if (this.issueType.value === "quality") {
          return reason.node.quality;
        } else if (this.issueType.value === "availability") {
          return reason.node.availability;
        } else {
          return false;
        }
      });

      return reasons.sort((a, b) => {
        if (a.node.order < b.node.order) {
          return -1;
        }
        if (a.node.order > b.node.order) {
          return 1;
        }
        return 0;
      });
    },
    ...mapGetters(["dbCache/machinesFromMachineGroup"]),
    dateTime() {
      if (this.issueDate && this.issueTime) {
        return new Date(`${this.issueDate}T${this.issueTime}`);
      }
      return null;
    }
  },
  watch: {
    machineGroup(newValue) {
      if (!newValue) {
        this.applyToAllMachines = false;
        this.issueTime = null;
        this.issueDate = null;
      }
    },
    showDialog(newValue) {
      if (!newValue) {
        this.machine = {};
        this.machineGroup = {};
        this.selected = false;
        this.otherText = "";
        this.applyToAllMachines = false;
        this.issueTime = moment().startOf("hour").format("H:m");
        this.saving = null;
        this.issueDate = null;
        this.activeTab = "calendar";
        this.issueType = null;
      }
    }
  },

  created() {
    this.loadMachines();
  },

  methods: {
    moment,
    loadMachines() {
      let query = `query getMachineGroup($id: ID!){
        machineGroup(id: $id){
          id
          ... on MachineGroupType{
            id
            pk
            name
            allMachines{
              id
              pk
              name
              reasonSet{
                edges{
                  node{
                    id
                    pk
                    text
                  }
                }
              }
            }
            allSubGroups{
              id
              pk
              name
              reasonSet{
                edges{
                  node{
                    id
                    pk
                    text
                  }
                }
              }
            }
            reasonSet{
              edges{
                node{
                  id
                  pk
                  text
                  performance
                  quality
                  availability
                }
              }
            }
          }
        }
        settings{
          zcbEscalationTime
        }
      }`;

      let variables = { id: this.machine_group_id };
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        this.reasons = res.data.data.machineGroup.reasonSet.edges;
        this.machineGroups = res.data.data.machineGroup.allSubGroups;
        this.machineGroups.unshift({
          id: res.data.data.machineGroup.id,
          pk: res.data.data.machineGroup.pk,
          name: res.data.data.machineGroup.name,
          reasonSet: res.data.data.machineGroup.reasonSet
        });
        this.machines = res.data.data.machineGroup.allMachines;
        if (res.data.data.settings.length > 0) {
          this.time = res.data.data.settings[0].zcbEscalationTime;
        }
      });
    },
    async createIssue() {
      this.saving = true;
      const query = `mutation ($issueInput: CreateIssueMutationInput!){
    createIssue(input: $issueInput) {
      issue{
        id,
        title,
        openedDate,
        closedDate,
        ticketedDate,
        issueStartDate,
        issueEndDate,
        machineId,
        machineName,
        machineGroupId,
        machineGroupName,
        issueTypeId,
        issueTypeName,
        reason,
        countermeasures,
        cause,
        solution
      }
    }
  }`;
      let startDate;
      let endDate;
      if (this.dateTime && this.machineGroup) {
        startDate = moment(this.dateTime).startOf("hour");
        endDate = moment(this.dateTime).endOf("hour");
      } else {
        const currentDate = moment();
        startDate = currentDate.startOf("hour").subtract(this.time, "hours");
        endDate = currentDate.endOf("hour");
      }
      const baseVariables = {
        issueInput: {
          autoEscalate: startDate.toISOString(),
          issueStartDate: startDate.toISOString(),
          issueEndDate: endDate.toISOString()
        }
      };
      if (this.selected === "other" && this.otherText) {
        baseVariables["issueInput"]["reason"] = this.otherText;
        if (this.issueType) {
          baseVariables["issueInput"]["other"] = `other-${this.issueType.value}`;
        }
      } else {
        baseVariables["issueInput"]["reason"] = this.selected.node.id;
      }
      let promiseArray = [];
      let machineIds = [];
      if (this.applyToAllMachines && this.machineGroup) {
        const machines = this["dbCache/machinesFromMachineGroup"](this.machineGroup.pk);
        for (const machine of machines) {
          if (!machineIds.includes(machine.pk)) {
            let variables = { ...baseVariables };
            variables["issueInput"]["machine"] = machine.pk;
            promiseArray.push(this.$http.post("graphql/", { query, variables: baseVariables }));
            machineIds.push(machine.pk);
          }
        }
      } else {
        if (this.machine) {
          baseVariables["issueInput"]["machine"] = this.machine.pk;
        }

        if (this.machineGroup) {
          baseVariables["issueInput"]["machineGroup"] = this.machineGroup.pk;
        } else if (!this.machine) {
          baseVariables["issueInput"]["machineGroup"] = this.machine_group_pk;
        }
        promiseArray.push(this.$http.post("graphql/", { query, variables: baseVariables }));
      }
      Promise.all(promiseArray)
        .then((responses) => {
          this.$message("ALERT", {
            text: this.$t("Reason Submitted"),
            type: "success"
          });
          for (const res of responses) {
            this.$emit("submitted", res.data.data.createIssue.issue);
          }
          this.showDialog = false;
          this.selected = false;
          this.saving = false;
          this.showDialog = false;
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
          this.showDialog = false;
        });
    },
    showTimePicker() {
      this.activeTab = "timer";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/theme_tokens";
@import "~@/scss/dropdown.scss";
</style>

<style scoped lang="scss">
.button-issue {
  min-width: 144px !important;
}

.dialog-btn {
  min-width: 149px !important;
}

.dialog-wrapper {
  background-color: var(--bg-secondary);
}

.dialog-textarea .v-text-field__slot textarea {
  padding-top: 16px;
}

.dialog-title {
  border-bottom: 1px solid var(--border-secondary);
}

.reason-btn {
  border: 1px solid var(--border-primary) !important;
  background: transparent !important;

  button {
    border: none !important;
    margin: 0 !important;
  }
}

.dialog-close {
  top: 16px;
  right: 16px;
}

::v-deep .v-label {
  color: var(--text-primary);
}

.root {
  width: 100%;
  background-color: var(--bg-secondary);
}
</style>
