var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalDialog',{staticClass:"cause-dialog",attrs:{"persistent":"","content-class":"issue-entry-dialog cause"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("Countermeasure For"))+" "+_vm._s(_vm.timeDisplay)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',[_c('div',{staticClass:"selections"},[(_vm.selectedReason)?_c('div',{staticClass:"selection",on:{"click":function($event){_vm.selectedReason = false}}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Reason")))]),_vm._v(" "),_c('div',{staticClass:"reason"},[_c('ReasonButtonWithTooltip',{attrs:{"text":_vm.selectedReason.reason,"is-selected":false,"action":function () {
                  _vm.selectedReason = false;
                }}})],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex"},[(!_vm.selectedReason)?_c('div',{staticClass:"reasons"},_vm._l((_vm.issues),function(issue){return _c('div',{key:issue.id,staticClass:"reason"},[_c('ReasonButtonWithTooltip',{attrs:{"text":issue.reason,"is-selected":_vm.selectedReason.id == issue.id,"action":function () {
                  _vm.selectReason(issue);
                }}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.selectedReason && _vm.cms)?_c('div',{staticClass:"cms"},[_vm._l((_vm.cms),function(cm){return _c('div',{key:'cm' + cm.node.id,staticClass:"countermeasure"},[_c('ReasonButtonWithTooltip',{attrs:{"text":cm.node.text,"is-selected":_vm.selectedCM == cm,"action":function () {
                  _vm.selectedCM = cm;
                },"disabled":_vm.selectedReasonCMs.includes(cm.node.text)}})],1)}),_vm._v(" "),_c('div',{key:"other",staticClass:"countermeasure"},[_c('ReasonButtonWithTooltip',{attrs:{"text":_vm.$t('Other'),"is-selected":_vm.selectedCM == 'other',"action":function () {
                  _vm.selectedCM = 'other';
                }}})],1)],2):_vm._e()]),_vm._v(" "),(_vm.selectedCM == 'other')?_c('div',{staticClass:"otherText"},[_c('v-text-field',{attrs:{"label":_vm.$t('Countermeasure')},model:{value:(_vm.otherText),callback:function ($$v) {_vm.otherText=$$v},expression:"otherText"}})],1):_vm._e()])]},proxy:true},{key:"actions",fn:function(){return [_c('Btn',{staticClass:"cause-dialog-btn",attrs:{"large":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("\n      "+_vm._s(_vm.$t("Cancel"))+"\n    ")]),_vm._v(" "),_c('Btn',{staticClass:"cause-dialog-btn",attrs:{"variant":"primary","primary":"","large":"","disabled":!_vm.selectedCM || (_vm.selectedCM == 'other' && _vm.otherText == ''),"loading":_vm.saving},on:{"click":_vm.updateIssue}},[_vm._v("\n      "+_vm._s(_vm.$t("Submit"))+"\n    ")])]},proxy:true}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }