<template>
  <ModalDialog
    v-model="showDialog"
    persistent
    class="cause-dialog"
    content-class="issue-entry-dialog cause"
  >
    <template #title> {{ $t("Root Cause For") }} {{ timeDisplay }} </template>

    <template #body>
      <div>
        <div class="selections">
          <div
            v-if="selectedReason"
            class="selection"
            @click="selectedReason = false"
          >
            <p class="label">{{ $t("Reason") }}</p>
            <div class="reason">
              <ReasonButtonWithTooltip
                :text="selectedReason.reason"
                :is-selected="false"
                :action="
                  () => {
                    selectedReason = false;
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="flex">
          <div
            v-if="!selectedReason"
            class="reasons"
          >
            <div
              v-for="issue in issues"
              :key="issue.id"
              class="reason"
            >
              <ReasonButtonWithTooltip
                :text="issue.reason"
                :is-selected="selectedReason.id == issue.id"
                :action="
                  () => {
                    selectReason(issue);
                  }
                "
              />
            </div>
          </div>
          <div
            v-if="causeOptions && selectedReason"
            class="cms"
          >
            <div
              v-for="cause in causeOptions"
              :key="'cause' + cause.node.id"
              class="cause"
            >
              <ReasonButtonWithTooltip
                :text="cause.node.text"
                :is-selected="selectedCause == cause"
                :action="
                  () => {
                    selectedCause = cause;
                  }
                "
                :disabled="selectedReasonCauses.includes(cause.node.text)"
              />
            </div>
            <div
              key="other"
              class="cause"
            >
              <ReasonButtonWithTooltip
                :text="$t('Other')"
                :is-selected="selectedCause == 'other'"
                :action="
                  () => {
                    selectedCause = 'other';
                  }
                "
              />
            </div>
          </div>
        </div>
        <div
          v-if="selectedCause == 'other'"
          class="otherText"
        >
          <v-text-field
            v-model="otherText"
            :label="$t('Root Cause')"
          >
          </v-text-field>
        </div>
      </div>
    </template>

    <template #actions>
      <Btn
        large
        class="cause-dialog-btn"
        @click="showDialog = false"
      >
        {{ $t("Cancel") }}
      </Btn>
      <Btn
        variant="primary"
        primary
        large
        :disabled="!selectedCause || (selectedCause == 'other' && otherText == '')"
        :loading="saving"
        class="cause-dialog-btn"
        @click="updateIssue"
      >
        {{ $t("Submit") }}
      </Btn>
    </template>
  </ModalDialog>
</template>
<script>
import moment from "moment";
import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import ReasonButtonWithTooltip from "@/components/ReasonButtonWithTooltip.vue";

export default {
  name: "TicketCauseDialog",
  components: { ModalDialog, Btn, ReasonButtonWithTooltip },
  // eslint-disable-next-line vue/require-prop-types
  props: ["issues", "time", "machine_id", "open"],
  data() {
    return {
      showDialog: true,
      reasons: {},
      causeOptions: [],
      selectedReason: false,
      selectedCause: false,
      saving: false,
      otherText: ""
    };
  },
  computed: {
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    selectedReasonCauses() {
      if (this.selectedReason.cause) {
        return this.selectedReason.cause;
      }
      return [];
    },
    causes() {
      let causes = [];
      this.issues.forEach((issue) => {
        if (issue.cause) {
          let issueCause = issue.cause
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll("'", "")
            .split(",");
          if (issueCause.length > 0) {
            causes = causes.concat(issueCause);
          }
        }
      });
      return causes;
    }
  },
  watch: {
    open(val) {
      this.showDialog = val;
    },
    showDialog(val) {
      if (!val) {
        this.$emit("close");
      }
    }
  },
  methods: {
    moment,
    selectReason(issue) {
      this.selectedReason = issue;
      this.causeOptions = [];
      if (issue.reason in this.reasons) {
        this.causeOptions = this.reasons[issue.reason];
      } else {
        const query = `query($reason_Text: String, $machine: [ID]!){
          issueCauses(reason_Text: $reason_Text, machine:$machine){
            edges{
              node{
                id
                text
              }
            }
          }
        }`;
        const variables = {
          reason_Text: issue.reason,
          machine: [this.machine_id]
        };
        this.$http
          .post("graphql/", { query: query, variables: variables })
          .then((res) => {
            this.reasons[issue.reason] = res.data.data.issueCauses.edges.sort((a, b) => {
              if (a.node.text < b.node.text) {
                return -1;
              }
              if (a.node.text > b.node.text) {
                return 1;
              }
              return 0;
            });
            this.causeOptions = this.reasons[issue.reason];
          })
          .catch((res) => {
            this.errors = res.errors;
          });
      }
    },
    updateIssue() {
      this.saving = true;
      const query = `mutation ($id: ID!, $issueInput: IssueInput!){
        updateIssue(globalId:$id, issueData: $issueInput) {
          issue{
            id,
            title,
            openedDate,
            closedDate,
            issueStartDate,
            issueEndDate,
            machineId,
            machineName,
            issueTypeId,
            issueTypeName,
            reason,
            countermeasures,
            cause,
            solution,
          }
        }
      }`;
      let variables = {
        id: this.selectedReason.id,
        issueInput: {}
      };
      if (this.selectedCause == "other" && this.otherText) {
        variables["issueInput"]["cause"] = this.otherText;
      } else {
        variables["issueInput"]["cause"] = this.selectedCause.node.text;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.$message("ALERT", { text: this.$t("Reason Submitted"), type: "success" });
          this.showDialog = false;
          this.selectedReason = false;
          this.selectedCause = false;
          this.causeOptions = [];
          this.saving = false;
          this.$emit("UpdatedCause", res.data.data.updateIssue.issue);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
        });
    }
  }
};
</script>
<style lang="scss">
.cause-dialog {
  .edit-btn {
    i {
      color: white;
    }
  }
  .issues {
    overflow: visible;
    width: 150px;
    min-width: 150px;
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);
    padding: 0.5rem;
    z-index: 9999;
    .issueText {
      max-height: 3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-y: auto;
    }
  }
  .dialogBtn {
    position: relative;
  }
}
.v-dialog {
  .flex {
    display: flex;
  }
  .reason {
    .v-btn {
      border: 2px solid rgba(0, 0, 0, 0);
      transition: all 0.25s;
      margin: 0.25rem;
    }
    .selected {
      border: 2px solid white;
    }
  }
  .cause {
    .v-btn {
      transition: all 0.25s;
      margin: 0.25rem;
      border: 2px solid rgba(0, 0, 0, 0);
    }
    .selected {
      border: 2px solid white;
    }
  }
}
.cause-dialog-btn {
  width: 144px;
}
</style>
