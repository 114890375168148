var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"solution-dialog"},[_c('ModalDialog',{staticClass:"cause-dialog",attrs:{"persistent":"","content-class":"issue-entry-dialog cause"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("Long Term Solution For"))+" "+_vm._s(_vm.timeDisplay)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',[_c('div',{staticClass:"selections"},[(_vm.selectedReason)?_c('div',{staticClass:"selection",on:{"click":function($event){_vm.selectedReason = false}}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Reason")))]),_vm._v(" "),_c('div',{staticClass:"reason"},[_c('ReasonButtonWithTooltip',{attrs:{"text":_vm.selectedReason.reason,"is-selected":false,"action":function () {
                    _vm.selectedReason = false;
                    _vm.selectedCause = false;
                  }}})],1)]):_vm._e(),_vm._v(" "),(_vm.selectedCause)?_c('div',{staticClass:"selection",on:{"click":function($event){_vm.selectedCause = false}}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Root Cause")))]),_vm._v(" "),_c('div',{staticClass:"reason"},[(_vm.selectedCause)?_c('ReasonButtonWithTooltip',{attrs:{"text":_vm.selectedCause,"is-selected":false,"action":function () {
                    _vm.selectedReason = false;
                    _vm.selectedCause = false;
                  }}}):_vm._e()],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex"},[(!_vm.selectedReason)?_c('div',{staticClass:"reasons"},_vm._l((_vm.filteredIssues),function(issue){return _c('div',{key:issue.id,staticClass:"reason"},[_c('ReasonButtonWithTooltip',{attrs:{"text":issue.reason,"is-selected":_vm.selectedReason.id == issue.id,"action":function () {
                    _vm.selectReason(issue);
                  }}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.selectedReason && _vm.causes && !_vm.selectedCause)?_c('div',{staticClass:"cms"},_vm._l((_vm.causes),function(cause){return _c('div',{key:'cause' + cause,staticClass:"countermeasure"},[_c('ReasonButtonWithTooltip',{attrs:{"text":cause,"is-selected":_vm.selectedCause == cause,"action":function () {
                    _vm.selectCause(cause);
                  }}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.selectedReason && _vm.selectedCause && _vm.solutionOptions)?_c('div',{staticClass:"solutions"},[_vm._l((_vm.solutionOptions),function(solution){return _c('div',{key:'solution' + solution,staticClass:"solution"},[_c('ReasonButtonWithTooltip',{attrs:{"text":solution.node.text,"is-selected":_vm.selectedSolution == solution,"action":function () {
                    _vm.selectedSolution = solution;
                  },"disabled":_vm.selectedReasonSolutions.includes(solution.node.text)}})],1)}),_vm._v(" "),(_vm.selectedCause)?_c('div',{key:"other",staticClass:"solution"},[_c('ReasonButtonWithTooltip',{attrs:{"text":_vm.$t('Other'),"is-selected":_vm.selectedSolution == 'other',"action":function () {
                    _vm.selectedSolution = 'other';
                  }}})],1):_vm._e()],2):_vm._e()]),_vm._v(" "),(_vm.selectedSolution == 'other')?_c('div',{staticClass:"otherText"},[_c('v-text-field',{attrs:{"label":_vm.$t('Solution')},model:{value:(_vm.otherText),callback:function ($$v) {_vm.otherText=$$v},expression:"otherText"}})],1):_vm._e()])]},proxy:true},{key:"actions",fn:function(){return [_c('Btn',{staticClass:"cause-dialog-btn",attrs:{"large":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("\n        "+_vm._s(_vm.$t("Cancel"))+"\n      ")]),_vm._v(" "),_c('Btn',{staticClass:"cause-dialog-btn",attrs:{"variant":"primary","primary":"","large":"","disabled":!_vm.selectedSolution || (_vm.selectedSolution == 'other' && _vm.otherText == ''),"loading":_vm.saving},on:{"click":_vm.updateIssue}},[_vm._v("\n        "+_vm._s(_vm.$t("Submit"))+"\n      ")])]},proxy:true}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }