<template>
  <div class="d-flex align-center">
    <btn
      v-if="showAddButton"
      color="primary"
      light
      icon
      fab
      small
      @click="$emit('click-add-button')"
    >
      <v-icon class="root">mdi-plus</v-icon>
    </btn>

    <span>
      <TextOverflowTooltip
        v-for="item in result.result"
        :key="item"
        :text="item"
        :text-length="60"
      >
      </TextOverflowTooltip>
    </span>

    <v-tooltip
      v-if="result.extraItems"
      top
      right
      :max-width="300"
    >
      <template #activator="{ on, attrs }">
        <v-chip
          class="ml-1 pl-1 pr-2 chip"
          x-small
          v-bind="attrs"
          v-on="on"
        >
          <!-- eslint-disable-line -->+{{ result.extraItems }}
        </v-chip>
      </template>
      <div class="text-subtitle-2 color--text-primary">
        <p
          v-for="item in result.extraItemsContent"
          :key="item"
        >
          {{ item }}
        </p>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import { toRefs, computed } from "@vue/composition-api";
import Btn from "@/ui/Btn/Btn.vue";
import TextOverflowTooltip from "@/ui/ToolTip/TextOverflowTooltip.vue";

export default {
  name: "TableMultivalue",
  components: { TextOverflowTooltip, Btn },
  props: {
    items: {
      type: [Array, String],
      default: () => []
    },
    showAddButton: {
      type: Boolean,
      default: false
    },
    maxCountItems: {
      type: Number,
      default: 1
    },
    isParseStringArray: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click-add-button"],

  setup(props) {
    const { items, isParseStringArray, maxCountItems } = toRefs(props);

    const resultObject = computed(() => {
      let extraItems = 0;
      let extraItemsContent;
      let result = isParseStringArray.value
        ? items.value.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "").split(",")
        : items.value;

      if (result && result.length > maxCountItems.value) {
        extraItems = result.length - maxCountItems.value;
        extraItemsContent = [...result].splice(maxCountItems.value, result.length);
        result = [...result].splice(0, maxCountItems.value);
      }

      return { result, extraItems, extraItemsContent };
    });

    return {
      result: resultObject
    };
  }
};
</script>

<style scoped>
.root {
  font-size: 20px;
}

.v-tooltip__content {
  border: 1px solid var(--border-primary) !important;
  background-color: var(--bg-primary);
  transform: translateX(-25%);
  padding: 12px;
  border-radius: 8px;
}

.chip {
  min-width: 22px;
}
</style>
