<template>
  <div v-if="isReady">
    <TicketAssignDialog
      v-if="assignedTicket"
      type="ldms"
      :open="Boolean(assignedTicket)"
      :ticket="formatIssue(assignedTicket)"
      :machine_group_id="machine_group_id"
      @close="assignedTicket = null"
      @success="handleRefresh"
    />

    <TicketCauseDialog
      v-if="selectedCause"
      :open="Boolean(selectedCause)"
      :issues="[formatIssue(selectedCause)]"
      :machine_id="selectedCause.machine && selectedCause.machine.id"
      :time="selectedCause.key_as_string"
      :tab="-1"
      @UpdatedCause="handleRefresh"
      @close="selectedCause = null"
    />

    <TicketLongTermSolutionDialog
      v-if="selectedSolution"
      :issues="[formatIssue(selectedSolution)]"
      :machine_id="selectedSolution.machine.pk"
      :time="selectedSolution.key_as_string"
      :tab="-1"
      @UpdatedSolution="handleRefresh"
      @close="selectedSolution = null"
    />

    <TicketsCountermeasureDialog
      v-if="selectedCountermeasure"
      :issues="[formatIssue(selectedCountermeasure)]"
      :machine_id="selectedCountermeasure.machine.pk"
      :time="selectedCountermeasure.key_as_string"
      :tab="-1"
      @UpdatedCM="handleRefresh"
      @close="selectedCountermeasure = null"
    />

    <!-- eslint-disable vue/no-mutating-props -->
    <DynamicTable
      item-key="graphQL_id"
      class="root"
      :items="items"
      :headers="headers"
      :calculate-widths="true"
      :page="config.page"
      :items-per-page="config.itemsPerPage"
      :server-items-length="totalItems"
      :sort-by="config.sortBy"
      :sort-desc="config.sortDesc"
      :loading="isFetchingTable"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Help Tickets... Please wait')"
      :dynamic-slots="[
        'title',
        'created_by_name',
        'issue.text',
        'machine_name',
        'datetime',
        'short_term_countermeasures',
        'long_term_solution',
        'root_cause',
        'status',
        'id',
        'assigned_names',
        'launch',
        'responding_names',
        'machine_name'
      ]"
      @update:items-per-page="config.itemsPerPage = $event"
      @update:page="config.page = $event"
      @update:sort-by="config.sortBy = $event"
      @update:sort-desc="config.sortDesc = $event"
    >
      <template #[`item.issue.text`]="{ item }">
        <div class="d-flex">
          <TicketTag
            v-if="item.issue"
            :class="[getColorByFlag(item)]"
            :issue="{ name: item.issue && item.issue.text }"
          ></TicketTag>
        </div>
      </template>

      <template #[`item.status`]="{ item }">
        <TicketStatus :status="item.status"></TicketStatus>
      </template>

      <template #[`item.datetime`]="{ item }">
        {{ moment(item.datetime).format($datetime.default) }}
      </template>

      <template #[`item.long_term_solution`]="{ item }">
        <TableMultivalue
          :items="item.long_term_solution"
          :show-add-button="Boolean(item.root_cause)"
          @click-add-button="selectedSolution = item"
        ></TableMultivalue>
      </template>

      <template #[`item.root_cause`]="{ item }">
        <TableMultivalue
          :items="item.root_cause"
          :show-add-button="true"
          @click-add-button="selectedCause = item"
        ></TableMultivalue>
      </template>

      <template #[`item.short_term_countermeasures`]="{ item }">
        <div>
          <TableMultivalue
            :items="item.short_term_countermeasures"
            :show-add-button="true"
            @click-add-button="selectedCountermeasure = item"
          ></TableMultivalue>
        </div>
      </template>

      <template #[`item.assigned_names`]="{ item }">
        <TableMultivalue
          :items="item.assigned_names"
          :show-add-button="true"
          @click-add-button="assignedTicket = item"
        ></TableMultivalue>
      </template>

      <template #[`item.responding_names`]="{ item }">
        <TableMultivalue
          :items="item.responding_names"
          :show-add-button="false"
        ></TableMultivalue>
      </template>

      <template #[`item.launch`]="{ item }">
        <v-btn
          small
          color="primary"
          icon
          :to="{ name: 'ticket', params: { ticket_id: item.graphQL_id } }"
          target="_blank"
          fab
        >
          <img :src="icon" />
        </v-btn>
      </template>

      <template #[`item.machine_name`]="{ item }">
        <span v-if="item.machine">
          {{ item.machine.name }}

          <router-link
            class="tickets-link"
            :to="{
              name: AppRoutes.machineKpi.name,
              params: {
                machine_id: item.machine.id,
                machine_pk: item.machine.pk
              }
            }"
          >
            <v-btn
              icon
              x-small
              color="primary"
            >
              <img
                width="16"
                height="16"
                alt=""
                :src="icon"
              />
            </v-btn>
          </router-link>
        </span>

        <span v-if="item.machine_group">
          {{ item.machine_group.name }}

          <router-link
            class="tickets-link"
            :to="{
              name: 'group',
              params: {
                machine_group_id: item.machine_group.id,
                machine_group_pk: item.machine_group.pk
              }
            }"
          >
            <v-btn
              icon
              x-small
              color="primary"
            >
              <img
                width="16"
                height="16"
                alt=""
                :src="icon"
              />
            </v-btn>
          </router-link>
        </span>
      </template>
    </DynamicTable>
  </div>

  <preloader-panel
    v-else
    class="pa-6"
  />
</template>

<script>
import { computed, getCurrentInstance, ref, toRefs } from "@vue/composition-api";
import DynamicTable from "@/ui/DynamicTable/DynamicTable.vue";
import PreloaderPanel from "@/ui/PreloaderPanel/PreloaderPanel.vue";
import * as moment from "moment-timezone";
import TicketStatus from "@/features/tickets/components/TicketStatus.vue";
import TicketTag from "@/features/tickets/components/TicketTag.vue";
import TableMultivalue from "@/ui/TableMultivalue/TableMultivalue.vue";
import icon from "../icons/v-open-link.svg";
import TicketAssignDialog from "@/features/tickets/components/TicketAssignDialog.vue";
import TicketCauseDialog from "@/features/tickets/components/TicketCauseDialog.vue";
import TicketLongTermSolutionDialog from "@/features/tickets/components/TicketLongTermSolutionDialog.vue";
import TicketsCountermeasureDialog from "@/features/tickets/components/TicketsCountermeasureDialog.vue";
import { AppRoutes } from "@/shared/app-routes";

export default {
  name: "TicketsTableLdms",
  components: {
    TicketsCountermeasureDialog,
    TicketLongTermSolutionDialog,
    TicketCauseDialog,
    TicketAssignDialog,
    TableMultivalue,
    TicketTag,
    TicketStatus,
    PreloaderPanel,
    DynamicTable
  },
  props: {
    totalItems: { type: Number, default: 0 },
    isFetchingTable: { type: Boolean, default: false },
    isReady: { type: Boolean, default: false },
    isShowMachineName: { type: Boolean, default: false },
    items: { type: Array, default: undefined },
    params: { type: Object, default: undefined },
    machine_group_id: { type: String, required: true },
    config: { type: Object, required: true },
    refresh: { type: Function, required: true }
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const $t = root.$t;
    const { items, isShowMachineName } = toRefs(props);
    const assignedTicket = ref(null);
    const selectedCause = ref(null);
    const selectedSolution = ref(null);
    const selectedCountermeasure = ref(null);

    const handleRefresh = () => {
      assignedTicket.value = null;
      selectedCause.value = null;
      selectedSolution.value = null;
      selectedCountermeasure.value = null;
      props.refresh();
    };

    const formatIssue = (issue) => {
      return {
        ...issue,
        reason: issue.issue && issue.issue.text,
        cause: issue.root_cause,
        id: issue.graphQL_id
      };
    };

    const getColorByFlag = (item) => {
      const issue = item && item.issue;
      if (issue.availability) {
        return "bg--graphics-2-orange";
      } else if (issue.performance) {
        return "bg--graphics-10-light-blue";
      } else if (issue.quality) {
        return "bg--graphics-9-yellow";
      }

      return "bg--graphics-1-blue";
    };

    const hidePagination = computed(() => items.value && items.value.length === 0);

    const TICKETS_TABLE_HEADERS = computed(() => {
      return [
        { text: "", value: "launch", sortable: false, width: "44px" },
        { text: $t("Title"), value: "title", sortable: false, width: "150px" },
        isShowMachineName.value
          ? { text: $t("Machine"), value: "machine_name", sortable: false, width: "130px" }
          : null,
        { text: $t("Date"), value: "datetime", sortable: true, width: "120px" },
        { text: $t("Category"), value: "category", sortable: false, width: "118px" },
        { text: $t("Issue"), value: "issue.text", sortable: false, width: "200px" },
        { text: $t("Ticket Status"), value: "status", sortable: false, width: "84px" },

        {
          text: $t("Short Term Countermeasures"),
          value: "short_term_countermeasures",
          sortable: false,
          width: "220px"
        },
        { text: $t("Root Cause"), value: "root_cause", sortable: false, width: "220px" },
        {
          text: $t("Long term solutions"),
          value: "long_term_solution",
          sortable: false,
          width: "220px"
        },
        {
          text: $t("Assigned"),
          value: "assigned_names",
          sortable: false,
          width: "170px"
        },
        {
          text: $t("Responding"),
          value: "responding_names",
          sortable: false,
          width: "170px"
        }
      ].filter(Boolean);
    });

    return {
      hidePagination,
      headers: TICKETS_TABLE_HEADERS,
      moment,
      icon,
      assignedTicket,
      selectedCause,
      selectedSolution,
      selectedCountermeasure,
      formatIssue,
      getColorByFlag,
      handleRefresh,
      AppRoutes
    };
  }
};
</script>

<style scoped lang="scss">
.root::v-deep {
  table-layout: fixed;

  table {
    table-layout: fixed;
    width: 100%;
  }

  @media (max-width: 600px) {
    table {
      table-layout: unset;
    }
  }

  th span {
    white-space: break-spaces;
    text-transform: uppercase;
  }

  td:first-child,
  th:first-child {
    padding-left: 8px;
    padding-right: 0;
  }

  th {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}
</style>
