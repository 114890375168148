<template>
  <div>
    <div class="d-flex mb-5">
      <div class="d-flex align-center">
        <div class="text-h6 color--text-primary">{{ data && data.name }}</div>
        <Btn
          v-if="!isMachine && !hideDrillDown"
          class="ml-3"
          large
          ghost
          text
          @click="$emit('click-drill-in', data)"
        >
          <v-icon class="mr-1">mdi-arrow-left-right</v-icon>
          {{ $t("Drill-In") }}
        </Btn>
      </div>
      <div class="ml-auto d-flex">
        <btn-toggle v-if="!isMachine && !hideDrillDown">
          <v-btn
            text
            class="text-uppercase text-button"
            min-width="128"
            large
            @click="tab = 'grid'"
          >
            {{ $t("Grid") }}
            <v-icon class="ml-2">mdi-table</v-icon>
          </v-btn>
          <v-btn
            v-if="isHasSubgroups"
            text
            min-width="128"
            class="text-uppercase text-button"
            large
            @click="tab = 'chart'"
          >
            {{ $t("Chart") }}
            <v-icon class="ml-2">mdi-chart-line</v-icon>
          </v-btn>
        </btn-toggle>

        <date-range-picker
          v-if="showChangeButton"
          id="weekPicker"
          class="ml-5 picker"
          :dates-in="dates"
          position="bottom right"
          append-icon="mdi-calendar-range-outline"
          :text-field-props="{
            filled: true
          }"
          @input="$emit('change', $event)"
        ></date-range-picker>
      </div>
    </div>

    <ZoneControlWeeklyTable
      v-if="tab === 'grid'"
      :shifts="shifts"
      :data="data"
    />

    <ZoneControlWeeklyChart
      v-if="tab === 'chart'"
      :key="dates[0]"
      :data="data"
      :machine_group_pk="data.id"
      :machine_group_id="data.graphql_id"
      :start-date="dates[0]"
      :end-date="dates[1]"
    />
  </div>
</template>

<script>
import { ref, toRefs, computed } from "@vue/composition-api";
import DateRangePicker from "@/components/form/DateRangePicker.vue";
import Btn from "@/ui/Btn/Btn.vue";
import BtnToggle from "@/components/BtnToggle.vue";
import ZoneControlWeeklyTable from "@/features/zone-control-weekly/components/ZoneControlWeeklyTable.vue";
import ZoneControlWeeklyChart from "@/features/zone-control-weekly/components/ZoneControlWeeklyChart.vue";
import { useGroupsHierarchy } from "@/features/group/useGroupsHierarchy";

export default {
  components: {
    ZoneControlWeeklyChart,
    ZoneControlWeeklyTable,
    BtnToggle,
    DateRangePicker,
    Btn
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isMachine: {
      type: Boolean,
      default: false
    },
    shifts: {
      type: Object,
      required: true
    },
    showChangeButton: {
      type: Boolean,
      default: false
    },
    dates: {
      type: Array,
      required: true
    }
  },
  emits: ["change", "click-drill-in"],
  setup(props) {
    const { data } = toRefs(props);
    const tab = ref("grid");
    const { groups } = useGroupsHierarchy();

    const hideDrillDown = computed(() => {
      return Boolean(data.value && data.value.shifts && data.value.shifts.length === 0);
    });

    const isHasSubgroups = computed(() => {
      if (!groups.value) {
        return false;
      }

      const group = groups.value.find((item) => item.pk === Number(data.value.id));

      return group && group.subGroupIds && group.subGroupIds.length;
    });

    return {
      tab,
      hideDrillDown,
      isHasSubgroups
    };
  }
};
</script>

<style scoped>
.picker {
  min-width: 249px;
  height: 44px;

  .v-input__slot {
    min-height: 44px !important;
  }

  .v-input__append-inner {
    margin-top: 10px;
  }
}
</style>
