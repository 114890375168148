import axios from "axios";

export const irisIngestionApi = {
  createUpload: (data) => axios.post("/aa/uploads/", data).then((res) => res.data),
  getDocuments: (query) => axios.get("/aa/documents/", { params: query }).then((res) => res.data),
  completeUpload: (id) => axios.post(`/aa/documents/${id}/uploads/`).then((res) => res.data),
  updateDocuments: (data) => axios.post(`/aa/documents/`, data).then((res) => res.data),
  resumeUpdateDocument: (id) =>
    axios.post(`/aa/documents/${id}/updates/postponed/`).then((res) => res.data),
  generateFileTitle: (data) =>
    axios.post(`/aa/llm-queries/file_name/`, data).then((res) => res.data),
  getPresignedUrl: (id) => axios.get(`/aa/documents/${id}/view_link/`).then((res) => res.data),
  deleteDocument: (id) => axios.delete(`/aa/documents/${id}/`).then((res) => res.data),
  getKnowledgeData: (query) =>
    axios.get("/aa/documents/knowledge_data/", { params: query }).then((res) => res.data),
  getKnowledgeDataItem: (tykId) =>
    axios.get(`/aa/documents/knowledge_data/${tykId}/`).then((res) => res.data),
  createTypeKnowledge: (data) =>
    axios.post("/aa/documents/knowledge_data/", data).then((res) => res.data),
  updateTypeKnowledge: (id, data) =>
    axios.patch(`/aa/documents/knowledge_data/${id}/`, data).then((res) => res.data),
  createTypeKnowledgeImage: (file) => {
    const data = new FormData();
    data.append("file", file);
    return axios.post("/aa/documents/knowledge_data/file/", data);
  },
  getKnowledgeDataItemEvents: (tykId) =>
    axios.get(`/aa/documents/knowledge_data/${tykId}/events/`).then((res) => res.data),
  approveTypeKnowledge: (id) =>
    axios.post(`/aa/documents/knowledge_data/${id}/approve/`).then((res) => res.data),
  rejectTypeKnowledge: (id, comment) =>
    axios.post(`/aa/documents/knowledge_data/${id}/reject/`, { comment }).then((res) => res.data)
};
