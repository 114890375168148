<template>
  <div>
    <div
      v-if="drawer"
      class="sidebar-overlay"
      @click="drawer = false"
    />
    <v-navigation-drawer
      v-model="drawer"
      right
      class="sidebar"
      :width="width"
      fixed
      temporary
      :hide-overlay="true"
    >
      <template #prepend>
        <slot name="prepend"></slot>
      </template>
      <template #default>
        <div>
          <div
            v-if="title"
            class="px-5 py-4 title"
          >
            <h6 class="text-h6">
              {{ title }}
            </h6>

            <div class="ml-auto d-flex align-center">
              <slot name="beforeClose"></slot>

              <v-btn
                icon
                class="btn-close"
                @click="drawer = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="px-4">
            <slot name="default"></slot>
          </div>
        </div>
      </template>
      <template #append>
        <slot name="append"></slot>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Sidebar",
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String],
      default: "400"
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);

        if (!value) {
          this.$emit("close");
        }
      }
    }
  }
};
</script>

<style scoped>
.title {
  border-bottom: 1px solid var(--border-secondary);
  position: relative;
  display: flex;
  align-items: center;
  color: var(--text-primary);
}

.sidebar {
  background-color: var(--bg-secondary) !important;
  border-left: 0;

  .v-navigation-drawer__border {
    display: none;
  }
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(33, 33, 33, 0.46);
  backdrop-filter: blur(1px);
  z-index: 1;
  height: 100vh;
}
</style>
