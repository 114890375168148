<template>
  <v-dialog
    content-class="assignableDialog"
    :value="open"
    persistent
    max-width="600px"
    @close="closeAssign"
  >
    <v-card>
      <v-card-title class="headline">
        <h3>{{ $t("Assign Users") }}</h3>
      </v-card-title>
      <v-card-text>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <v-text-field
              v-model="userSearch"
              append-icon="mdi-magnify"
              :label="$t('Search Users to Assign')"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <div class="assignable">
              <p
                v-for="user in filteredUsers"
                :key="user.id"
                @click="newUsers.push(user)"
              >
                {{ user.firstName }} {{ user.lastName }}
              </p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <h2>{{ $t("New Users") }}</h2>
            <div class="newUsers">
              <p
                v-for="(new_user, new_user_index) in newUsers"
                :key="new_user.id"
                @click="newUsers.splice(new_user_index, 1)"
              >
                {{ new_user.firstName }} {{ new_user.lastName }}
              </p>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <btn
          class="btn-assign"
          primary
          large
          @click="closeAssign"
        >
          {{ $t("Cancel") }}
        </btn>
        <btn
          class="btn-assign"
          variant="primary"
          large
          :disabled="newUsers.length === 0"
          :loading="assigningTicket"
          @click="helpAssign(ticket)"
        >
          {{ $t("Assign") }}
        </btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Btn from "@/ui/Btn/Btn.vue";

export default {
  name: "TicketAssignDialog",
  components: { Btn },

  props: {
    type: { type: String, default: "p911" },
    ticket: { type: Object, default: () => undefined },
    open: { type: Boolean, default: false, required: true },
    machine_group_id: { type: String, default: "", required: true }
  },

  data() {
    return {
      assignDialog: true,
      userSearch: "",
      users: [],
      newUsers: [],
      assigningTicket: false
    };
  },

  computed: {
    ...mapGetters({
      user: "session/User",
      statuses: "app/Statuses"
    }),
    filteredUsers() {
      if (!this.userSearch) {
        return this.users;
      }
      return this.users.filter((user) => {
        return `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(this.userSearch.toLowerCase());
      });
    }
  },

  created() {
    this.checkMachineGroupPrioritization();
  },
  methods: {
    checkMachineGroupPrioritization() {
      let query = "";
      let variables = {};
      if (this.machine_group_id) {
        query = `query getMachineGroup($id: ID!){
          machineGroup(id: $id){
            id
            ... on MachineGroupType{
              prioritization
            }
          }
          users{
            id,
            firstName,
            lastName,
          }
        }`;
        variables = { id: this.machine_group_id };
      } else {
        query = `query {
          users{
            id,
            firstName,
            lastName,
          }
        }`;
      }

      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        if (
          res.data.data &&
          "machineGroup" in res.data.data &&
          "prioritization" in res.data.data.machineGroup
        ) {
          this.prioritization = res.data.data.machineGroup.prioritization;
        }
        this.users = res.data.data.users;
      });
    },

    closeAssign() {
      this.assignDialog = false;
      this.newUsers = [];
      this.$emit("close");
    },

    helpAssign(ticket) {
      this.assigningTicket = true;
      let newUserIds = [];
      this.newUsers.forEach((user) => {
        newUserIds.push(user.id);
      });

      let query;
      let variables;

      if (this.type === "p911") {
        query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
          updateHelpTicket(globalId:$id, helpData:$helpTicketData){
            ok,
            helpTicket{
              assignedTo,
              assignedToNames,
            }
          }
        }`;

        variables = {
          id: ticket.id,
          helpTicketData: {
            assignNew: newUserIds
          }
        };
      } else {
        query = `mutation ($id: ID!, $issueData: IssueInput!){
          updateIssue(globalId:$id, issueData:$issueData){
            ok,
            issue{
              assignedTo,
              assignedToNames,
            }
          }
        }`;

        variables = {
          id: ticket.id,
          issueData: {
            assignNew: newUserIds
          }
        };
      }

      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          this.newUsers = [];
          this.assigningTicket = false;
          this.$emit("success");
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    }
  }
};
</script>
<style scoped>
.btn-assign {
  min-width: 168px !important;
}
</style>
